<template>
    <div class="hero-carousel">
        <NCarousel
            v-if="slides"
            class="relative hero-height"
            :class="{ 'pointer-events-none': slides.length <= 1 }"
            :show-dots="false"
            :show-arrow="slides.length > 1"
            :draggable="slides.length > 1"
            :autoplay="CONFIG_OPTIONS.hero.slider.autoplay.enabled"
            :interval="CONFIG_OPTIONS.hero.slider.autoplay.interval"
        >
            <div
                class="relative w-full h-full"
                v-for="(slide, index) in slides"
                :key="index"
            >
                <img
                    class="anti-lazy aspect-video object-cover w-full h-full hero-height object-center block"
                    :style="{
                        'object-position': focalPoint(slide.media[0]),
                    }"
                    :src="slide.media[0]?.url"
                    :alt="slide.media[0]?.alt"
                    v-if="
                        slide.media[0]?.kind == 'image' || !slide.media[0]?.url
                        /* If media unavailable, display placeholder img */
                    "
                />
                <video
                    class="aspect-video object-cover w-full h-full hero-height object-center block"
                    v-else-if="slide.media[0]?.kind == 'video'"
                    preload="metadata"
                    :poster="slide.media[0]?.url"
                    autoplay
                    muted
                    loop
                    playsinline
                >
                    <source :src="slide.media[0]?.url" />
                </video>
                <div
                    class="grid grid-cols-[1fr_minmax(auto,1400px)_1fr] absolute top-0 left-0 right-0 bottom-0 z-20 hero-height"
                >
                    <div
                        class="col-start-2 col-end-2 row-start-1 row-end-2 relative text-white max-w-14/24 xs:max-w-full w-full px-side pb-16 m:pb-10 s:pb-6 xs:pb-8 z-[45]"
                        :class="
                            centered
                                ? 'self-center justify-self-center text-center'
                                : 'justify-self-start self-start mt-auto'
                        "
                    >
                        <div>
                            <h2 class="text-white text-f2">
                                {{ slide.ueberschrift }}
                            </h2>
                            <div
                                class="text-white text-medium-400 pt-6 whitespace-pre-line"
                            >
                                {{ slide.lead }}
                            </div>
                            <div
                                v-if="slide.buttons.length"
                                class="flex gap-2 xs:flex-col pt-6"
                                :class="
                                    centered
                                        ? 'justify-center'
                                        : 'justify-start'
                                "
                            >
                                <div v-for="(button, index) in slide.buttons">
                                    <a
                                        class="pointer-events-auto"
                                        :class="
                                            index < 1
                                                ? CONFIG_OPTIONS.hero
                                                      .primaryButtonClass
                                                : CONFIG_OPTIONS.hero
                                                      .secondaryButtonClass
                                        "
                                        :href="button?.linkfeld?.url"
                                    >
                                        <span>{{
                                            button?.linkfeld?.text
                                        }}</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="CONFIG_OPTIONS.global.blockDecoration.enabled"
                            class="decoration-offset w-full"
                        />
                    </div>
                    <CopyrightTag
                        :copyrightText="slide.media[0]?.copyright"
                        :license="slide.media[0]?.license"
                        position="top-right"
                    />
                </div>
                <div
                    v-if="props.data.darkenBackground"
                    class="bg-black bg-opacity-30 absolute top-0 left-0 right-0 bottom-0 z-10 pointer-events-none"
                ></div>
            </div>

            <template #arrow="{ prev, next }">
                <div
                    class="custom-arrow-container pointer-events-none xs:hidden"
                >
                    <div
                        class="custom-arrow"
                        :class="
                            centered ? 'arrows-centered' : 'arrows-bottom-right'
                        "
                    >
                        <button
                            :aria-label="t('goToPreviousPicture')"
                            type="button"
                            class="custom-arrow--left pointer-events-auto"
                            @click="prev"
                        >
                            <i
                                :class="[
                                    CONFIG_OPTIONS.global.iconStyle,
                                    CONFIG_OPTIONS.global.leftIcon,
                                ]"
                            ></i>
                        </button>
                        <button
                            :aria-label="t('goToNextPicture')"
                            type="button"
                            class="custom-arrow--right pointer-events-auto"
                            @click="next"
                        >
                            <i
                                :class="[
                                    CONFIG_OPTIONS.global.iconStyle,
                                    CONFIG_OPTIONS.global.rightIcon,
                                ]"
                            ></i>
                        </button>
                        <div
                            v-if="CONFIG_OPTIONS.global.blockDecoration.enabled"
                            class="decoration-offset w-full"
                        />
                    </div>
                </div>
            </template>
        </NCarousel>
        <CopyrightTag v-if="slides.length === 1" :copyrightText="slides[0].media[0]?.copyright" :license="slides[0].media[0]?.license" position="top-right" />
    </div>
</template>

<script lang="ts" setup>
import { NCarousel } from "naive-ui";
import {
    AssetInterface,
    Module_Hero_BlockType,
    Module_Slide_BlockType,
} from "@gql-types/types.generated";
import { CONFIG_OPTIONS, t } from "@src/globals";
import { focalPoint } from "../helpers/style.helper.ts";
import CopyrightTag from "./common/CopyrightTag.vue";

const props = defineProps<{
    data: Module_Hero_BlockType;
}>();

const slides = props.data.children as Module_Slide_BlockType[];
const centered = CONFIG_OPTIONS.hero.centered ?? CONFIG_OPTIONS.global.centered;
const darkenBackground = props.data.darkenBackground;
</script>

<style lang="scss" scoped>
.carousel-img {
    width: 100%;
    height: 240px;
    object-fit: cover;
}

.custom-arrow-container {
    @apply absolute w-full h-full bottom-16 m:bottom-10 s:bottom-6 xs:bottom-16;
}

.custom-arrow {
    @apply max-w-content mx-auto absolute bottom-0 right-side left-side px-side;
    @apply flex justify-end gap-x-4 flex-wrap;
    font-size: 1.25rem;
}

.arrows-centered {
    @screen minM {
        @apply top-1/2 justify-between;
    }
}

.custom-arrow button {
    @apply inline-flex items-center justify-center w-12 h-12
     text-white border border-white rounded-full cursor-pointer;
    @apply transition-all duration-300;
}

@screen minL {
    @media (prefers-reduced-motion: no-preference) {
        .custom-arrow button:hover {
            background-color: rgba(255, 255, 255, 0.2);
        }
    }
}
</style>
