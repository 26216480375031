<template>
    <!-- Lighbox (yumpu magazines) -->
    <div class="max-h-[100vh] fixed inset-0 bg-white z-50" v-if="showLightBox">
        <div
            class="relative flex items-center justify-center w-12 h-12 cursor-pointer ml-auto"
            @click="toggleLightBox()"
        >
            <i class="fa-solid fa-xmark text-large"></i>
        </div>
        <div v-if="additionalData && lightBoxIndex !== null" class="h-full">
            <div
                v-html="additionalData[lightBoxIndex]"
                class="iframe-container h-full"
            ></div>
        </div>
    </div>

    <div class="grid grid-cols-4 gap-8 content:gap-6 m:gap-4 mt-20 mb-16">
        <a
            v-for="(item, index) in shownEntries"
            class="flex flex-col gap-3 teaser-card"
            :class="[
                { 'cursor-pointer': openInLightBox },
                { doHover: openInLightBox || item.linkfeldNoText?.url },
            ]"
            :href="openInLightBox ? null : item.linkfeldNoText?.url"
            @click="() => (openInLightBox ? toggleLightBox(index) : null)"
            :key="index"
        >
            <div class="fade-in">
                <div class="teaser-img-container overflow-y-hidden relative">
                    <img
                        :src="item.bild[0]?.url"
                        :alt="item.bild[0]?.alt || 'Portfolio Bild'"
                        class="object-cover w-full"
                        :style="{
                            'object-position': focalPoint(item.bild[0]),
                        }"
                        :class="[
                            { 'rounded-2xl': roundedCorners },
                            props.aspectAuto ? 'aspect-auto' : 'aspect-square',
                        ]"
                    />
                    <CopyrightTag
                        :copyrightText="item.bild[0]?.copyright"
                        :license="item.bild[0]?.license"
                        :roundedEdge="CONFIG_OPTIONS.global.roundedCorners"
                    />
                </div>
                <div class="text-f5 pt-2 item-title teaser-name word-breaker">
                    {{ item.ueberschrift }}
                </div>
                <div
                    class="text-regular cursor-pointer ckeditor-content"
                    v-html="item.teaserText"
                />
            </div>
        </a>
    </div>

    <div
        v-if="shownEntries.length < entries.length && entries.length > 8"
        class="flex justify-center fade-in"
    >
        <a class="btn-primary s:w-full" @click="showMore()">
            <span>{{ t("loadMore") }}</span>
        </a>
    </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { Module_TeaserEintrag_BlockType } from "@gql-types/types.generated";
import { CONFIG_OPTIONS, t } from "@src/globals";
import { focalPoint } from "../helpers/style.helper.ts";
import CopyrightTag from "./common/CopyrightTag.vue";

const props = defineProps<{
    data: Array<Module_TeaserEintrag_BlockType>;
    aspectAuto?: boolean;
    openInLightBox?: boolean;
    additionalData?: any;
}>();

const entries = props.data;
let shownEntries = ref(entries.slice(0, 8));
let showLightBox = ref(false);
let lightBoxIndex = ref<number>(null);

const roundedCorners = CONFIG_OPTIONS.global.roundedCorners;

function showMore() {
    shownEntries.value = entries.slice(0, shownEntries.value.length + 8);
}

function toggleLightBox(index: number = null) {
    lightBoxIndex.value = index;
    const newVisible = !showLightBox.value;
    showLightBox.value = newVisible;
    // Disable scrolling while lightbox is visible
    document.body.classList.toggle("overflow-hidden", newVisible);
}
</script>
<style lang="scss" scoped>
.teaser-card {
    @screen minL {
        .teaser-img-container,
        .teaser-name {
            @apply transition-all duration-300;
            @apply underline decoration-transparent;
        }

        &.doHover {
            @media (prefers-reduced-motion: no-preference) {
                &:hover {
                    .teaser-img-container {
                        @apply scale-105;
                    }
                    .teaser-name {
                        @apply decoration-black;
                    }
                }
                .ckeditor-content * {
                    @apply cursor-pointer;
                }
            }
        }
    }
}

.word-breaker {
    word-break: break-word;
    hyphens: auto;
}
</style>

<style lang="scss">
.iframe-container > iframe {
    @apply w-full h-[calc(100%-3rem)];
}
</style>
