<template>
    <div
        class="copyrightContainer z-[43] absolute inset-0"
        @mouseenter="show = true"
        @mouseleave="show = false"
    >
        <div
            v-if="copyrightText && license"
            class="copyrightTag z-[44] absolute py-0.5 pl-0.5 pr-1.5 bg-[rgba(0,0,0,0.3)] text-white text-xs"
            :class="positionClassMap[position]"
        >
            <i
                v-for="icon in licenseIcon?.icons || ['fa-copyright']"
                class="pl-1"
                :class="[licenseIcon?.kit || 'fa-regular', icon]"
            ></i>
            {{ copyrightText }}
        </div>
    </div>
</template>

<style lang="scss" scoped>
.copyrightContainer {
    @apply transition-opacity duration-500;
    opacity: 0;
    &:hover {
        opacity: 1;
    }
    .copyrightTag {
        @apply max-w-18/24 cursor-default;
        @apply transition-all duration-500;
        @apply opacity-75;
        &:hover {
            @apply scale-125 opacity-100;
        }
    }
}
</style>

<script setup lang="ts">
import { ref } from "vue";

const props = defineProps({
    copyrightText: { type: String, required: true },
    license: { type: String, required: true },
    position: {
        type: String as () =>
            | "top-left"
            | "top-right"
            | "bottom-left"
            | "bottom-right"
            | "float-top",
        default: "bottom-right",
    },
    roundedEdge: { type: Boolean, default: false },
});

const show = ref(false);

const positionClassMap = {
    "top-left": `top-0 left-0 rounded-br-lg origin-top-left ${props.roundedEdge ? "rounded-tl-xl" : ""}`,
    "top-right": `top-0 right-0 rounded-bl-lg origin-top-right ${props.roundedEdge ? "rounded-tr-xl" : ""}`,
    "bottom-left": `bottom-0 left-0 rounded-tr-lg origin-bottom-left ${props.roundedEdge ? "rounded-bl-xl" : ""}`,
    "bottom-right": `bottom-0 right-0 rounded-tl-lg origin-bottom-right ${props.roundedEdge ? "rounded-br-xl" : ""}`,
    "float-top":
        "top-4 s:top-16 left-1/2 -translate-x-1/2 rounded-lg origin-top",
};

const licenseIconMap = {
    "C All-Rights-Reserved": {
        kit: "fa-regular",
        icons: ["fa-copyright"],
    },
    "CC 0": {
        kit: "fa-brands",
        icons: ["fa-creative-commons", "fa-creative-commons-zero"],
    },
    "CC BY": {
        kit: "fa-brands",
        icons: ["fa-creative-commons", "fa-creative-commons-by"],
    },
    "CC BY-SA": {
        kit: "fa-brands",
        icons: [
            "fa-creative-commons",
            "fa-creative-commons-by",
            "fa-creative-commons-sa",
        ],
    },
    "CC BY-ND": {
        kit: "fa-brands",
        icons: [
            "fa-creative-commons",
            "fa-creative-commons-by",
            "fa-creative-commons-nd",
        ],
    },
    "CC BY-NC": {
        kit: "fa-brands",
        icons: [
            "fa-creative-commons",
            "fa-creative-commons-by",
            "fa-creative-commons-nc",
        ],
    },
    "CC BY-NC-SA": {
        kit: "fa-brands",
        icons: [
            "fa-creative-commons",
            "fa-creative-commons-by",
            "fa-creative-commons-nc",
            "fa-creative-commons-sa",
        ],
    },
    "CC BY-NC-ND": {
        kit: "fa-brands",
        icons: [
            "fa-creative-commons",
            "fa-creative-commons-by",
            "fa-creative-commons-nc",
            "fa-creative-commons-nd",
        ],
    },
};

const licenseIcon = ref<{}>(licenseIconMap[props.license]);
</script>
